import { newAssessoriaMobilitat as createAssessoria } from '../../api/assessories/realitzades/newAssessoriaMobilitat';
import createApiModule from '../createApiModule';

const newAssessoriaMobilitatModule = createApiModule({
  name: 'newAssessoriaMobilitat',
  apiMethod: createAssessoria,
  onSuccess: () => ({
    message: "L'assessoria s'ha realitzat correctament.",
    path: '/assessories',
  }),
  onError: () => ({
    message: "Error realitzant l'assessoria",
  }),
  onValidationError: () => ({
    message: "Error realitzant l'assessoria",
  }),
});

export const {
  start: newAssessoriaMobilitat,
  success: newAssessoriaMobilitatOk,
  error: newAssessoriaMobilitatKo,
} = newAssessoriaMobilitatModule.slice.actions;

export default newAssessoriaMobilitatModule;
