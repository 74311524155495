import { routePermission } from '../../types/auth/routePermissions';

export const RoutePermissions: routePermission[] = [
  {
    path: '/',
    permissions: ['*'],
  },
  {
    path: '/professionals',
    permissions: ['write_professional', 'read_professional'],
  },
  {
    path: '/professionals/*',
    permissions: ['write_professional'],
  },
  {
    path: '/calendari',
    permissions: ['write_cita', 'read_cita'],
  },
  {
    path: '/calendari/cita/nova',
    permissions: ['write_cita'],
  },
  {
    path: '/consultes',
    permissions: ['write_consultes', 'read_consultes'],
  },
  {
    path: '/consultes/*',
    permissions: ['write_consultes'],
  },
  {
    path: '/assessories',
    permissions: ['write_reservar_assesories', 'read_reservar_assesories'],
  },
  {
    path: '/assessories/*',
    permissions: ['write_reservar_assesories'],
  },
  {
    path: '/centres_educatius',
    permissions: ['write_centres', 'read_centres'],
  },
  {
    path: '/activitats/*',
    permissions: ['write_activitats', 'read_activitats'],
  },
  {
    path: '/oficines/*',
    permissions: ['write_activitats', 'read_activitats'],
  },
  {
    path: '/configuracio/*',
    permissions: ['write_configuracio', 'read_configuracio'],
  },
];
