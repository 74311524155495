import { useParamSelector } from '../../hooks/useParamSelector';
import { selectHasAnyPermission } from '../../store/auth/selectors';
import { createContext, useContext } from 'react';
import { CrudContextType } from '../../types/auth/permissionsContext';

export const CentresContext = createContext<CrudContextType>(
  {} as CrudContextType,
);
export const useCentresContext = () => useContext(CentresContext);

interface ProviderProps {
  children: React.ReactNode;
}
// eslint-disable-next-line react/prop-types
export const CentresProvider: React.FC<ProviderProps> = ({ children }) => {
  const canWrite = useParamSelector(selectHasAnyPermission, ['write_cita']);

  const canRead = useParamSelector(selectHasAnyPermission, [
    'write_cita',
    'read_cita',
  ]);

  const canAssign = useParamSelector(selectHasAnyPermission, ['assign_cita']);

  const value = {
    canWrite,
    canRead,
    canAssign,
  };

  return (
    <CentresContext.Provider value={value}>{children}</CentresContext.Provider>
  );
};
