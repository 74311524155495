import { useParamSelector } from '../../hooks/useParamSelector';
import { selectHasAnyPermission } from '../../store/auth/selectors';
import { createContext, useContext } from 'react';
import { CrudContextType } from '../../types/auth/permissionsContext';

export const ProfessionalsContext = createContext<CrudContextType>(
  {} as CrudContextType,
);
export const useProfessionalsContext = () => useContext(ProfessionalsContext);

interface ProviderProps {
  children: React.ReactNode;
}
export const ProfessionalsProvider: React.FC<ProviderProps> = ({
  // eslint-disable-next-line react/prop-types
  children,
}) => {
  const canWrite = useParamSelector(selectHasAnyPermission, [
    'write_professional',
  ]);

  const canRead = useParamSelector(selectHasAnyPermission, [
    'write_professional',
    'read_professional',
  ]);

  const value = {
    canWrite,
    canRead,
    canAssign: false,
  };

  return (
    <ProfessionalsContext.Provider value={value}>
      {children}
    </ProfessionalsContext.Provider>
  );
};
