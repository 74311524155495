import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import toQueryParams from '../../utils/toQueryParams';
import { AssessoriaDisponibilitatDate } from '../../types/assessories/assessoria_disponibilitat';

export interface GetCitesDTO {
  tipo?: string;
  oficina_id?: string;
}

export interface DisponibilitatsDTO {
  data: AssessoriaDisponibilitatDate[];
}

export const findCites = async (
  data: GetCitesDTO,
): Promise<Result<DisponibilitatsDTO, APIValidationError>> => {
  return validatedApiCall<DisponibilitatsDTO>(
    `/api/cites/disponibilitats?${toQueryParams(
      data as unknown as Record<string, string>,
    )}`,
    {
      method: 'GET',
      auth: true,
    },
  );
};
