/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIError } from '../../types/api/api';
import {
  AuthDTO,
  ChangePasswordDTO,
  LoginDTO,
  UserInfo,
} from '../../types/auth/auth';
import { Permission } from '../../types/auth/permissions';
import { UpdatePasswordDTO } from '../../types/forgotPassword/forgotPassword';

export type AuthState = {
  loading: boolean;
  errors: APIError | null;
  user: null | {
    permissions: Permission[];
    data: UserInfo;
  };
};

export const initialState: AuthState = {
  loading: false,
  errors: null,
  user: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, _action: PayloadAction<LoginDTO>) {
      state.loading = true;
      state.errors = null;
      state.user = null;
    },
    loginOk(state, action: PayloadAction<AuthDTO>) {
      const { user, permissions } = action.payload;
      state.loading = false;
      state.errors = null;
      state.user = {
        permissions,
        data: user,
      };
    },
    loginKo(state, action: PayloadAction<APIError>) {
      state.loading = false;
      state.errors = action.payload;
      state.user = null;
    },
    logout() {
      return { ...initialState };
    },
    changePassword(state, _action: PayloadAction<ChangePasswordDTO>) {
      state.loading = true;
      state.errors = null;
    },
    changePasswordOk(state) {
      state.loading = false;
      state.errors = null;
    },
    changePasswordKo(state, action: PayloadAction<APIError>) {
      state.loading = false;
      state.errors = action.payload;
    },
    createNewPassword(state, _action: PayloadAction<UpdatePasswordDTO>) {
      state.loading = true;
      state.errors = null;
      state.user = null;
    },
  },
});

export const {
  login,
  loginOk,
  loginKo,
  logout,
  changePassword,
  changePasswordOk,
  changePasswordKo,
  createNewPassword,
} = authSlice.actions;

export default authSlice;
