import { useParamSelector } from '../../hooks/useParamSelector';
import { selectHasAnyPermission } from '../../store/auth/selectors';
import { createContext, useContext } from 'react';

interface CrudContextType {
  canWrite: boolean;
  canRead: boolean;
  canAssign: boolean;
  canReadAssessoria: boolean;
  canAssignAssessoria: boolean;
}

export const CalendariContext = createContext<CrudContextType>(
  {} as CrudContextType,
);
export const useCalendariContext = () => useContext(CalendariContext);

interface ProviderProps {
  children: React.ReactNode;
}
// eslint-disable-next-line react/prop-types
export const CalendariProvider: React.FC<ProviderProps> = ({ children }) => {
  const canWrite = useParamSelector(selectHasAnyPermission, ['write_cita']);

  const canRead = useParamSelector(selectHasAnyPermission, [
    'write_cita',
    'read_cita',
    'write_reservar_assesories',
  ]);

  const canAssign = useParamSelector(selectHasAnyPermission, [
    'assign_cita',
    'write_anadir_assesories',
  ]);

  const canReadAssessoria = useParamSelector(selectHasAnyPermission, [
    'write_reservar_assesories',
    'read_cita',
  ]);

  const canAssignAssessoria = useParamSelector(selectHasAnyPermission, [
    'write_anadir_assesories',
  ]);

  const value = {
    canWrite,
    canRead,
    canAssign,
    canReadAssessoria,
    canAssignAssessoria,
  };

  return (
    <CalendariContext.Provider value={value}>
      {children}
    </CalendariContext.Provider>
  );
};
