import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AssessoriaRealitzada } from '../../../../types/assessories/assessoria_realitzada.d';
import { UUID } from '../../../../types/standard';
import { getAssessoria } from '../../../../store/assessories/assessoriaModule';
import {
  selectAssessoria,
  selectAssessoriaLoading,
  selectNewAssessoriaAcademicaLoading,
  selectNewAssessoriaEmprenedoriaLoading,
  selectNewAssessoriaHabitatgeLoading,
  selectNewAssessoriaLaboralLoading,
  selectNewAssessoriaMobilitatLoading,
} from '../../../../store/assessories/selectors';
import { AssessoriaTipusEnum } from '../../../../types/assessories/assessoria_tipus.d';
import { iEspecifica } from './iEspecifica';
import { getSectorsProfessionals } from '../../../../store/sectorsProfessionals/getSectorsProfessionalsModule';
import { EspecificaCreator } from './EspecificaCreator';

export function RealitzadaViewModel() {
  const dispatch = useDispatch();
  // view state scope
  const [error, setError] = useState('');

  const [activeEspecifica, setActiveEspecifica] = useState<iEspecifica | null>(
    null,
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  function _save(data: AssessoriaRealitzada, especifica: iEspecifica | null) {
    if (especifica) {
      especifica.save(
        {
          assessoria_id: data.assessoria?.id,
          ...data,
        },
        dispatch,
      );
    }
  }

  function _get(id: UUID) {
    dispatch(getAssessoria(id));
  }

  function _delete() {
    setError(error);
  }

  function loadSectorsProfessionals() {
    dispatch(getSectorsProfessionals);
  }

  const assessoria = useSelector(selectAssessoria);
  const isLoading = useSelector(selectAssessoriaLoading);
  const academicaSaving = useSelector(selectNewAssessoriaAcademicaLoading);
  const emprenedoriaSaving = useSelector(
    selectNewAssessoriaEmprenedoriaLoading,
  );
  const habitatgeSaving = useSelector(selectNewAssessoriaHabitatgeLoading);
  const laboralSaving = useSelector(selectNewAssessoriaLaboralLoading);
  const mobilitatSaving = useSelector(selectNewAssessoriaMobilitatLoading);
  const newServerErrors = null;
  const updateServerErrors = null;

  const [methods, setMethods] = useState(null);

  const createEspecifica = () => {
    const especifica = assessoria
      ? EspecificaCreator.createEspecifica(
          AssessoriaTipusEnum[assessoria?.tipus],
          assessoria,
        )
      : null;

    setActiveEspecifica(especifica);
  };

  return {
    activeEspecifica,
    assessoria,
    loadSectorsProfessionals,
    save: _save,
    get: _get,
    delete: _delete,
    isLoading,
    isSaving:
      academicaSaving ||
      emprenedoriaSaving ||
      habitatgeSaving ||
      laboralSaving ||
      mobilitatSaving,
    serverErrors: newServerErrors || updateServerErrors,
    createEspecifica,
    methods,
    setMethods,
  };
}
