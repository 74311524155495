import { newAssessoriaHabitatge as createAssessoria } from '../../api/assessories/realitzades/newAssessoriaHabitatge';
import createApiModule from '../createApiModule';

const newAssessoriaHabitatgeModule = createApiModule({
  name: 'newAssessoriaHabitatge',
  apiMethod: createAssessoria,
  onSuccess: () => ({
    message: "L'assessoria s'ha realitzat correctament.",
    path: '/assessories',
  }),
  onError: () => ({
    message: "Error realitzant l'assessoria",
  }),
  onValidationError: () => ({
    message: "Error realitzant l'assessoria",
  }),
});

export const {
  start: newAssessoriaHabitatge,
  success: newAssessoriaHabitatgeOk,
  error: newAssessoriaHabitatgeKo,
} = newAssessoriaHabitatgeModule.slice.actions;

export default newAssessoriaHabitatgeModule;
