export type AssessoriaTipus =
  | 'academica'
  | 'laboral'
  | 'mobilitat_internacional'
  | 'emprenedoria'
  | 'habitatge';

export enum AssessoriaTipusEnum {
  'academica' = 'Acadèmica',
  'laboral' = 'Laboral',
  'mobilitat_internacional' = 'Mobilitat internacional',
  'emprenedoria' = 'Emprenedoria',
  'habitatge' = 'Habitatge',
}

export enum AssessoriaUserEnum {
  'academica' = 'educaciojove@bcn.cat',
  'laboral' = 'laboraljove@bcn.cat',
  'mobilitat_internacional' = 'mobilitatjove@bcn.cat',
  'emprenedoria' = 'emprenedoriajove@bcn.cat',
  'habitatge' = 'habitatgejove@bcn.cat',
}
