import { APIError } from '../../types/api/api';
import { RootState } from '../store';
import { Permission } from '../../types/auth/permissions';
import { Role } from '../../types/auth/roles';

export const selectAuthSlice = (state: RootState) => state.auth;

export const selectAuthUser = (state: RootState) =>
  selectAuthSlice(state)?.user || null;

export const selectAuthId = (state: RootState) =>
  selectAuthSlice(state)?.user?.data.id;

export const selectAuthData = (state: RootState) =>
  selectAuthUser(state)?.data || null;

export const selectIsAdmin = (state: RootState): boolean =>
  selectAuthUser(state)?.data.role === ('admin' as Role);

export const selectIsInformador = (state: RootState): boolean =>
  selectAuthUser(state)?.data.role === ('informador' as Role) ||
  selectAuthUser(state)?.data.role === ('informador_centre_escolar' as Role) ||
  selectAuthUser(state)?.data.role === ('informador_integral' as Role);

export const selectIsSuportCoordinacio = (state: RootState): boolean =>
  selectAuthUser(state)?.data.role === ('suport_coordinacio' as Role);

export const selectPasswordChange = (state: RootState) =>
  selectAuthSlice(state)?.user?.data.force_password_change || false;

export const selectUserFullname = (state: RootState) =>
  selectAuthSlice(state)?.user?.data.nom_complet || '';

export const selectIsLoggedIn = (state: RootState) => !!selectAuthUser(state);

export const selectAuthLoading = (state: RootState) =>
  selectAuthSlice(state)?.loading;

export const selectAuthErrors = (state: RootState) =>
  selectAuthSlice(state)?.errors;

export const selectServerErrors = (state: RootState): APIError | null =>
  selectAuthSlice(state)?.errors;

export const selectUserRoles = (state: RootState) =>
  selectAuthData(state)?.role;

export const selectUserPermissions = (state: RootState): Permission[] =>
  selectAuthUser(state)?.permissions || [];

export const selectHasAnyPermission = (
  state: RootState,
  permissions: Permission[],
) => {
  const userPerms = selectUserPermissions(state);
  return permissions.some((p) => userPerms.includes(p));
};
