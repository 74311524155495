import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { connect } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../store/store';
import ValidatedSelect from '../../../../../components/common/Form/ValidatedSelect/ValidatedSelect';
import { AssessoriaRealitzadaLlocEnum } from '../../../../../types/assessories/assessoria_realitzada_lloc.d';
import { AssessoriaDuradaEnum } from '../../../../../types/assessories/assessoria_durada.d';
import {
  selectTematiques,
  selectTematiquesErrors,
  selectTematiquesLoading,
} from '../../../../../store/ambits/selectors';
import { getTematiques } from '../../../../../store/ambits/getTematiquesModule';
import { useMountEffect } from '../../../../../hooks/useMountEffect';
import { AssessoriaTipus } from '../../../../../types/assessories/assessoria_tipus';
import { APIError } from '../../../../../types/api/api';
import {
  selectAcademiques,
  selectAcademiquesErrors,
  selectAcademiquesLoading,
} from '../../../../../store/academiques/selectors';
import { getAcademiques } from '../../../../../store/academiques/getAcademiquesModule';
import { Academiques } from '../../../../../types/assessories/academiques';
import { AssessoriaAcademicaDerivacioEnum } from '../../../../../types/assessories/assessoria_academica_derivacio.d';
import ValidatedMultiSelect from '../../../../../components/common/Form/ValidatedMultiSelect/ValidatedMultiSelect';
import { enumToSelectOptionArray } from '../../../../../utils/enumToSelectOptionArray';
import useTematica from '../../../../../hooks/useTematica';
import {
  Tematica,
  tematicaToSelectOption,
} from '../../../../../types/shared/tematica.d';
import { sortArrayByKey } from '../../../../../utils/sortArrayByKey';
import { sortEnumByValue } from '../../../../../utils/sortEnumByValue';

interface AssessoriaAcademicaDataFormProps {
  schema: any;
  loadTematiques: (payload: AssessoriaTipus) => void;
  tematiquesServerErrors: APIError | null;
  tematiquesLoading: boolean;
  tematiques: Tematica[];
  loadAcademiques: () => void;
  academiquesServerErrors: APIError | null;
  academiquesLoading: boolean;
  academiques: Academiques[];
}

const AssessoriaAcademicaDataForm = ({
  schema,
  loadTematiques,
  tematiques,
  loadAcademiques,
  academiquesLoading,
  academiques,
}: AssessoriaAcademicaDataFormProps) => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();

  useMountEffect(() => loadAcademiques());
  useMountEffect(() => loadTematiques('academica'));

  const [lloc, setLloc] = useState<string | null>(null);

  function handleLlocChange(selected: string) {
    setLloc(AssessoriaRealitzadaLlocEnum[selected]);
  }

  const {
    tematiquesPlaceholder,
    subTematiquesPlaceholder,
    handleTematicaChange,
    groupedSubTematiques,
  } = useTematica();

  function getAcademiquesPlaceholder() {
    return academiquesLoading
      ? 'Carregant àmbits acadèmics...'
      : academiques.length > 0
        ? 'Selecciona un àmbit acadèmic'
        : 'No hi ha àmbits acadèmics';
  }

  const [selectedAcademica, setSelectedAcademica] = useState<string>('');

  return (
    <>
      <ValidatedSelect
        schema={schema}
        label="Lloc de realització"
        errors={errors}
        placeholder="Selecciona on s'ha realitzat"
        select={{
          ...register('realitzada'),
          onChange: (e) => handleLlocChange(e.currentTarget.value),
        }}
      >
        {Object.entries(AssessoriaRealitzadaLlocEnum).map((item) => (
          <option key={item[0]} value={item[0]}>
            {[item[1]]}
          </option>
        ))}
      </ValidatedSelect>
      {lloc === AssessoriaRealitzadaLlocEnum.caaj && (
        <ValidatedSelect
          schema={schema}
          label="Durada"
          errors={errors}
          placeholder="Selecciona quan a durat"
          select={{
            ...register('durada'),
          }}
        >
          {Object.entries(AssessoriaDuradaEnum).map((item) => (
            <option key={item[0]} value={item[0]}>
              {[item[1]]}
            </option>
          ))}
        </ValidatedSelect>
      )}
      <ValidatedMultiSelect
        schema={schema}
        label="Temàtica"
        errors={errors}
        placeholder={tematiquesPlaceholder}
        select={{
          ...register('tematica_id'),
        }}
        onChange={handleTematicaChange}
        options={sortArrayByKey(tematiques, 'name').map(tematicaToSelectOption)}
        control={control}
        controller={Controller}
      />
      <ValidatedMultiSelect
        schema={schema}
        label="Subtemàtica"
        errors={errors}
        placeholder={subTematiquesPlaceholder}
        select={{
          ...register('subtematica_id'),
        }}
        options={groupedSubTematiques}
        control={control}
        controller={Controller}
      />
      {(selectedAcademica === '' || selectedAcademica === 'cfgs_cfgm') && (
        <ValidatedSelect
          schema={schema}
          label="CFGM - CFGS"
          errors={errors}
          placeholder={getAcademiquesPlaceholder()}
          select={{
            ...register('cfgs_cfgm'),
            onChange: (e) =>
              setSelectedAcademica(
                e.currentTarget.value !== '' ? 'cfgs_cfgm' : '',
              ),
          }}
        >
          {sortArrayByKey(academiques, 'name')
            .filter((academica) => academica.formacio === 'cfgs_cfgm')
            .map((academica) => (
              <option key={academica.id} value={academica.id}>
                {academica.name}
              </option>
            ))}
        </ValidatedSelect>
      )}
      {(selectedAcademica === '' || selectedAcademica === 'universitat') && (
        <ValidatedSelect
          schema={schema}
          label="Universitat"
          errors={errors}
          placeholder={getAcademiquesPlaceholder()}
          select={{
            ...register('universitat'),
            onChange: (e) =>
              setSelectedAcademica(
                e.currentTarget.value !== '' ? 'universitat' : '',
              ),
          }}
        >
          {sortArrayByKey(academiques, 'name')
            .filter((academica) => academica.formacio === 'universitat')
            .map((academica) => (
              <option key={academica.id} value={academica.id}>
                {academica.name}
              </option>
            ))}
        </ValidatedSelect>
      )}
      <ValidatedMultiSelect
        schema={schema}
        label="Derivació?"
        errors={errors}
        placeholder="Selecciona si és una derivació"
        select={{
          ...register('derivacio'),
        }}
        options={enumToSelectOptionArray(
          sortEnumByValue(AssessoriaAcademicaDerivacioEnum),
        )}
        control={control}
        controller={Controller}
      />
    </>
  );
};

export default connect(
  (state: RootState) => ({
    tematiques: selectTematiques(state),
    tematiquesServerErrors: selectTematiquesErrors(state),
    tematiquesLoading: selectTematiquesLoading(state),
    academiques: selectAcademiques(state),
    academiquesServerErrors: selectAcademiquesErrors(state),
    academiquesLoading: selectAcademiquesLoading(state),
  }),
  (dispatch: AppDispatch) => ({
    loadTematiques: (payload: string) => {
      dispatch(getTematiques(payload));
    },
    loadAcademiques: () => {
      dispatch(getAcademiques({}));
    },
  }),
)(AssessoriaAcademicaDataForm);
