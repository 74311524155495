import { useParamSelector } from '../../hooks/useParamSelector';
import { selectHasAnyPermission } from '../../store/auth/selectors';
import { createContext, useContext } from 'react';
import { CrudContextType } from '../../types/auth/permissionsContext';

export const AssessoriesContext = createContext<CrudContextType>(
  {} as CrudContextType,
);
export const useAssessoriesContext = () => useContext(AssessoriesContext);

interface ProviderProps {
  children: React.ReactNode;
}
export const AssessoriesProvider: React.FC<ProviderProps> = ({
  // eslint-disable-next-line react/prop-types
  children,
}) => {
  const canWrite = useParamSelector(selectHasAnyPermission, [
    'write_reservar_assesories',
  ]);

  const canRead = useParamSelector(selectHasAnyPermission, [
    'write_reservar_assesories',
    'read_cita',
  ]);

  const canAssign = useParamSelector(selectHasAnyPermission, [
    'write_anadir_assesories',
  ]);

  const value = {
    canWrite,
    canRead,
    canAssign,
  };

  return (
    <AssessoriesContext.Provider value={value}>
      {children}
    </AssessoriesContext.Provider>
  );
};
