import { useParamSelector } from '../../hooks/useParamSelector';
import { selectHasAnyPermission } from '../../store/auth/selectors';
import { createContext, useContext } from 'react';
import { CrudContextType } from '../../types/auth/permissionsContext';

export const ConsultesContext = createContext<CrudContextType>(
  {} as CrudContextType,
);
export const useConsultesContext = () => useContext(ConsultesContext);

interface ProviderProps {
  children: React.ReactNode;
}
// eslint-disable-next-line react/prop-types
export const ConsultesProvider: React.FC<ProviderProps> = ({ children }) => {
  const canWrite = useParamSelector(selectHasAnyPermission, [
    'write_consultes',
  ]);

  const canRead = useParamSelector(selectHasAnyPermission, [
    'write_consultes',
    'read_consultes',
  ]);

  const value = {
    canWrite,
    canRead,
    canAssign: false,
  };

  return (
    <ConsultesContext.Provider value={value}>
      {children}
    </ConsultesContext.Provider>
  );
};
