export type Curs =
  | '1r_eso'
  | '2n_eso'
  | '3r_eso'
  | '4t_eso'
  | '1r_batx'
  | '2n_batx'
  | 'pfi'
  | 'cfgm'
  | 'cfgs'
  | 'cfa'
  | 'universitats';

export enum CursEnum {
  '1r_eso' = '1r ESO',
  '2n_eso' = '2n ESO',
  '3r_eso' = '3r ESO',
  '4t_eso' = '4t ESO',
  '1r_batx' = '1r Batx',
  '2n_batx' = '2n Batx',
  'pfi' = 'PFI',
  'cfgm' = 'CFGM',
  'cfgs' = 'CFGS',
  'cfa' = 'CFA',
  'universitats' = 'Universitats',
}
