import { useParamSelector } from '../../../../hooks/useParamSelector';
import { selectHasAnyPermission } from '../../../../store/auth/selectors';
import { createContext, useContext } from 'react';
import { CrudContextType } from '../../../../types/auth/permissionsContext';

export const ControlContext = createContext<CrudContextType>(
  {} as CrudContextType,
);
export const useControlContext = () => useContext(ControlContext);

interface ProviderProps {
  children: React.ReactNode;
}
export const ControlProvider: React.FC<ProviderProps> = ({
  // eslint-disable-next-line react/prop-types
  children,
}) => {
  const canWrite = useParamSelector(selectHasAnyPermission, [
    'write_configuracio',
  ]);

  const canRead = useParamSelector(selectHasAnyPermission, [
    'read_configuracio',
  ]);

  const canAssign = useParamSelector(selectHasAnyPermission, []);

  const value = {
    canWrite,
    canRead,
    canAssign,
  };

  return (
    <ControlContext.Provider value={value}>{children}</ControlContext.Provider>
  );
};
