import InputField from '../../../../components/common/Form/InputField/InputField';
import TextArea from '../../../../components/common/Form/TextArea/TextArea';
import { AssessoriaAcompanyantEnum } from '../../../../types/assessories/assessoria_acompanyant.d';
import { AssessoriaLaboral } from '../../../../types/assessories/assessoria_laboral.d';
import { AssessoriaLaboralDerivacioEnum } from '../../../../types/assessories/assessoria_laboral_derivacio.d';

interface SectionLaboralProps {
  realitzada: AssessoriaLaboral;
}

const SectionLaboral: React.FC<SectionLaboralProps> = ({
  realitzada,
}: SectionLaboralProps) => {
  return (
    <>
      <InputField
        label="Acompanyant"
        input={{
          type: 'text',
          defaultValue: realitzada.acompanyant
            ? AssessoriaAcompanyantEnum[realitzada.acompanyant]
            : '',
          readOnly: true,
        }}
      />
      <InputField
        label="Assistens"
        input={{
          type: 'text',
          defaultValue: realitzada?.assistents,
          readOnly: true,
        }}
      />
      <InputField
        label="Sector professional"
        input={{
          type: 'text',
          defaultValue: realitzada?.sector_professional?.name,
          readOnly: true,
        }}
      />
      <TextArea
        label="Temàtica"
        textarea={{
          defaultValue:
            realitzada?.tematiques?.map((el) => el.name).join(', ') || [],
          readOnly: true,
          name: 'tematica',
        }}
      />
      <TextArea
        label="Sub temàtica"
        textarea={{
          defaultValue:
            realitzada?.subtematiques?.map((el) => el.name).join(', ') || [],
          readOnly: true,
          name: 'subtematica',
        }}
      />
      <TextArea
        label="Derivacio"
        textarea={{
          defaultValue:
            realitzada?.derivacio
              ?.map((el) => AssessoriaLaboralDerivacioEnum[el])
              .join(', ') || [],
          readOnly: true,
          name: 'derivacio',
        }}
      />
      <InputField
        label="Valoració assessor"
        input={{
          type: 'text',
          defaultValue: realitzada?.valoracio_assessor,
          readOnly: true,
        }}
      />
      <TextArea
        label="Comentaris"
        textarea={{
          defaultValue: realitzada?.comentaris,
          readOnly: true,
          name: 'comentaris',
        }}
      />
    </>
  );
};

export default SectionLaboral;
