export type RangEdat =
  | '12-13'
  | '14-15'
  | '16-19'
  | '20-24'
  | '25-29'
  | '30-34'
  | '<=35';

export enum RangEdatEnum {
  '12-13' = '12-13',
  '14-15' = '14-15',
  '16-19' = '16-19',
  '20-24' = '20-24',
  '25-29' = '25-29',
  '30-34' = '30-34',
  '<=35' = '<=35',
}
