import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext } from 'react';

import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';

import { APIError } from '../../../../../types/api/api';
import useValidatorAPI from '../../../../../hooks/useValidatorAPI';
import FormLayout from '../../../../../components/common/Layouts/FormLayout/FormLayout';
import ValidatedSelect from '../../../../../components/common/Form/ValidatedSelect/ValidatedSelect';
import Loading from '../../../../../components/common/Loading/Loading';
import ButtonsGroup from '../../../../../components/common/Buttons/ButtonsGroup/ButtonsGroup';
import Button from '../../../../../components/common/Buttons/Button/Button';
import GlobalError from '../../../../../components/common/Form/GlobalError/GlobalError';
import ValidatedInput from '../../../../../components/common/Form/ValidatedInput/ValidatedInput';
import LineSeparator from '../../../../../components/common/Form/LineSeparator/LineSeparator';
import { AssessoriesContext } from '../../../AssessoriesContext';

import {
  AssessoriaRealitzada,
  NumAssistentsEnum,
  ValoracioEnum,
} from '../../../../../types/assessories/assessoria_realitzada.d';
import SectionHeading from '../../../../../components/common/Form/SectionHeading/SectionHeading';
import { AssessoriaInscripcioEnum } from '../../../../../types/assessories/assessoria_inscripcio.d';
import { iEspecifica } from '../iEspecifica';
import { AssessoriaAcompanyantEnum } from '../../../../../types/assessories/assessoria_acompanyant.d';
import ValidatedTextArea from '../../../../../components/common/Form/ValidatedTextArea/ValidatedTextArea';
import { RangEdatEnum } from '../../../../../types/consultes/rang_edat.d';
import { GenderEnum } from '../../../../../types/users/gender.d';
import { CursEnum } from '../../../../../types/consultes/curs.d';

interface RealitzadaDataFormProps {
  assessoriaRealitzada?: AssessoriaRealitzada;
  isSaving: boolean;
  serverErrors: APIError | null;
  isNew: boolean;
  isRealitzada: boolean;
  isLoading: boolean;
  onSave: (data: AssessoriaRealitzada) => void;
  especifica: iEspecifica;
}

const RealitzadaDataForm = ({
  isSaving,
  serverErrors,
  isNew,
  onSave,
  especifica,
}: RealitzadaDataFormProps) => {
  const { canWrite } = useContext(AssessoriesContext);

  const validationSchema = especifica?.validationSchema;
  const formMethods = useForm<AssessoriaRealitzada>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, register } = formMethods;
  const { errors } = formMethods.formState;

  const [globalError] = useValidatorAPI(
    serverErrors,
    formMethods.setError,
    formMethods.formState,
  );

  const { assessoria } = especifica;
  const addSubmitData = (data: AssessoriaRealitzada) => {
    onSave({
      ...data,
      assessoria_id: assessoria?.id,
    });
  };

  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <FormProvider {...formMethods}>
      <FormLayout
        variant="profile"
        formTitle={'Realitzar assessoria'}
        onSubmit={handleSubmit(addSubmitData)}
        messageRequired={true}
      >
        <SectionHeading title={'Dades persona usuària'} />
        <ValidatedInput
          schema={validationSchema}
          errors={errors}
          label="Professional qui reserva"
          input={{
            defaultValue: assessoria?.professional?.nom,
            readOnly: true,
            type: 'text',
            ...register('assessoria.professional.nom'),
          }}
        />
        <ValidatedInput
          schema={validationSchema}
          errors={errors}
          label="Nom"
          input={{
            defaultValue: assessoria?.jove?.nom,
            readOnly: true,
            type: 'text',
            ...register('assessoria.jove_nom'),
          }}
        />
        <ValidatedInput
          schema={validationSchema}
          errors={errors}
          label="Cognoms"
          input={{
            defaultValue: assessoria?.jove?.cognoms,
            readOnly: true,
            type: 'text',
            ...register('assessoria.jove_cognoms'),
          }}
        />
        <ValidatedInput
          schema={validationSchema}
          errors={errors}
          label="Telèfon"
          input={{
            defaultValue: assessoria?.jove?.telefon,
            readOnly: true,
            type: 'text',
            ...register('assessoria.jove_telefon'),
          }}
        />
        <ValidatedInput
          schema={validationSchema}
          errors={errors}
          label="Edat"
          input={{
            defaultValue: assessoria ? RangEdatEnum[assessoria?.edat] : '',
            readOnly: true,
            type: 'text',
            ...register('assessoria.edat'),
          }}
        />
        <ValidatedInput
          schema={validationSchema}
          errors={errors}
          label="Email"
          input={{
            defaultValue: assessoria?.jove?.email,
            readOnly: true,
            type: 'text',
            ...register('assessoria.jove_email'),
          }}
        />
        <ValidatedInput
          schema={validationSchema}
          errors={errors}
          label="Genère"
          input={{
            defaultValue: assessoria ? GenderEnum[assessoria?.genere] : '',
            readOnly: true,
            type: 'text',
            ...register('assessoria.genere'),
          }}
        />
        <ValidatedInput
          schema={validationSchema}
          errors={errors}
          label="Formació"
          input={{
            defaultValue: assessoria ? CursEnum[assessoria?.formacio] : '',
            readOnly: true,
            type: 'text',
            ...register('assessoria.formacio'),
          }}
        />
        <ValidatedInput
          schema={validationSchema}
          errors={errors}
          label="Ocupació"
          input={{
            defaultValue: assessoria?.ocupacio,
            readOnly: true,
            type: 'text',
            ...register('assessoria.ocupacio'),
          }}
        />
        <SectionHeading title={'Assessoria'} />
        <ValidatedInput
          schema={validationSchema}
          errors={errors}
          label="ID Assessoria"
          input={{
            defaultValue: assessoria?.slug,
            readOnly: true,
            type: 'text',
            ...register('assessoria.slug'),
          }}
        />
        <ValidatedSelect
          schema={validationSchema}
          label="Inscripció"
          errors={errors}
          placeholder="Selecciona com s'ha inscrit"
          select={{
            ...register('inscripcio'),
          }}
        >
          {Object.entries(AssessoriaInscripcioEnum).map((item) => (
            <option key={item[0]} value={item[0]}>
              {[item[1]]}
            </option>
          ))}
        </ValidatedSelect>
        <ValidatedSelect
          schema={validationSchema}
          label="Nombre d'assistents"
          errors={errors}
          placeholder=""
          select={{
            ...register('assistents'),
          }}
        >
          {Object.entries(NumAssistentsEnum).map((item) => (
            <option key={item[0]} value={item[0]}>
              {[item[1]]}
            </option>
          ))}
        </ValidatedSelect>
        <ValidatedSelect
          schema={validationSchema}
          label="Acompanyant"
          errors={errors}
          placeholder="Selecciona un acompanyant"
          select={{
            ...register('acompanyant'),
          }}
        >
          {Object.entries(AssessoriaAcompanyantEnum).map((item) => (
            <option key={item[0]} value={item[0]}>
              {[item[1]]}
            </option>
          ))}
        </ValidatedSelect>
        {especifica.component}
        <ValidatedSelect
          schema={validationSchema}
          label="Valoració"
          errors={errors}
          placeholder="Dona una valoració"
          select={{
            ...register('valoracio'),
          }}
        >
          {Object.entries(ValoracioEnum).map((item) => (
            <option key={item[0]} value={item[0]}>
              {[item[1]]}
            </option>
          ))}
        </ValidatedSelect>
        <ValidatedTextArea
          schema={validationSchema}
          errors={errors}
          label="Comentaris"
          textarea={{
            ...register('comentaris'),
          }}
        />
        {/* Server-side global error */}
        {globalError && <GlobalError message={globalError} />}

        <div className="lineSeparator" style={{ marginTop: '20px' }}>
          <LineSeparator />
        </div>
        {isSaving ? (
          <Loading />
        ) : (
          <ButtonsGroup variant={'column'}>
            <React.Fragment>
              {canWrite && (
                <Button variant="positive">
                  {isNew ? 'Guardar' : 'Guardar'}
                </Button>
              )}
              <Button type="button" variant="negative" onClick={goBack}>
                {isNew ? 'Cancelar' : 'Tornar'}
              </Button>
            </React.Fragment>
          </ButtonsGroup>
        )}
      </FormLayout>
    </FormProvider>
  );
};

export default React.memo(RealitzadaDataForm);
