import { newAssessoriaEmprenedoria as createAssessoria } from '../../api/assessories/realitzades/newAssessoriaEmprenedoria';
import createApiModule from '../createApiModule';

const newAssessoriaEmprenedoriaModule = createApiModule({
  name: 'newAssessoriaEmprenedoria',
  apiMethod: createAssessoria,
  onSuccess: () => ({
    message: "L'assessoria s'ha realitzat correctament.",
    path: '/assessories',
  }),
  onError: () => ({
    message: "Error realitzant l'assessoria",
  }),
  onValidationError: () => ({
    message: "Error realitzant l'assessoria",
  }),
});

export const {
  start: newAssessoriaEmprenedoria,
  success: newAssessoriaEmprenedoriaOk,
  error: newAssessoriaEmprenedoriaKo,
} = newAssessoriaEmprenedoriaModule.slice.actions;

export default newAssessoriaEmprenedoriaModule;
